import Layout from '@/layout';
var userRouter = {
  path: '/TTT',
  component: Layout,
  name: 'TTT',
  meta: {
    title: 'TTT',
    icon: 'clipboard'
  },
  children: [{
    path: 'machine',
    component: function component() {
      return import('@/views/TTT/machine/index');
    },
    name: 'machine',
    meta: {
      title: '矿机',
      icon: ''
    }
  }, {
    path: 'orderList',
    component: function component() {
      return import('@/views/TTT/orderList/index');
    },
    name: 'orderList',
    meta: {
      title: '矿机订单列表',
      icon: ''
    }
  }, {
    path: 'property',
    component: function component() {
      return import('@/views/TTT/property/index');
    },
    name: 'property',
    meta: {
      title: '资产',
      icon: ''
    }
  }, {
    path: 'fTransferList',
    component: function component() {
      return import('@/views/TTT/fTransferList/index');
    },
    name: 'fTransferList',
    meta: {
      title: '转账地址',
      icon: ''
    }
  }, {
    path: 'checking',
    component: function component() {
      return import('@/views/TTT/checking/index');
    },
    name: 'checking',
    meta: {
      title: '检查交易hash',
      icon: ''
    }
  }, {
    path: 'poolUser',
    component: function component() {
      return import('@/views/TTT/poolUser/index');
    },
    name: 'poolUser',
    meta: {
      title: '流动池用户',
      icon: ''
    }
  }]
};
export default userRouter;